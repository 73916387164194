import './qrIndex.css';
import { useState, useEffect } from 'react';
import {
  FaSortDown,
  FaPlus,
  FaExternalLinkAlt,
  FaSistrix,
} from 'react-icons/fa';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import { customBaseUrl, httpGet, httpPost } from '../../store/http';
import GeneratedQr from './generatedQrModal';
import ExcelUpload from './excelUpload';
import { Col, Progress, Row } from 'antd';
import ExportQr from './exportCards';
import GenQR from './genCards';
import { hideLoader, showLoader } from '../../utils/loader';
import Swal from 'sweetalert2';
import GenCard from './genCards';
import moment from 'moment';
import { refresh } from 'less';


const Date = () => {
  const [active, setActive] = useState(false);
  return (
    <div className="dropdown date-dropdown">
      <div
        className="dropdown-btn date-dropdown-btn"
        onClick={(e) => setActive(!active)}
      >
        {' '}
        Date
        <p className="arrow-down">
          <FaSortDown />
        </p>
      </div>
      {active && (
        <div className="dropdown-content date-dropdown-content">
          <div className="dropdown-item date-dropdown-item">
            <h6 className="start-date">Start Date</h6> 
            <input type="date" />
          </div>
          <div className="dropdown-item date-dropdown-item">
            <h6 className="end-date">End State</h6>
            <input type="date" />
          </div>
        </div>
      )}
    </div>
  );
};

const Search = ({ selected,setSelectedCards, selectedCards, getAll, refresh, setRefresh,  setOpenExport,  setOpenExcel, setSelected, handleManageMarketers }) => {
  const [openGenerateModal, setOpenGenerateModal] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const options = ['id', 'Email', 'name'];
  const [count, setCount] = useState("");
  const [showProgress, setShowProgress] = useState(false);
  const [prog, setProg] = useState(0);


 const handleGenerate = async()=>{
  if(!count) return Swal.fire('Oops', 'Count field is empty')
  setOpenGenerateModal(false)
  setShowProgress(true);
  let response = await httpGet(`${customBaseUrl.qrCodeUrl}/api/v1/qrcode/generateQRCode/count/${count}`)
 showLoader();
  if (response.status){    
    hideLoader();
    Swal.fire('Success', response?.message);
    //setQRStatus( response?.message);
    setShowProgress(false);
    setCount('');
    getAll();
  }else{
    hideLoader();
    console.log(response?.message)
  }
  }

  const handleReexport = async()=>{
     const postData= {
        "dummyCardIds": selectedCards
      }
    let response = await httpPost(`${customBaseUrl.cardUrl}/api/cards/re-export`,postData)
   showLoader();
    if (response.status){    
      hideLoader();
      Swal.fire('Success', response?.message);
      //setQRStatus( response?.message);
      setShowProgress(false);
      setSelectedCards([]);
      getAll();
    }else{
      hideLoader();
      Swal.fire('Success', response?.message);
      setSelectedCards([]);
      console.log(response?.message)
    }
    }

  const handleGenerateModal = () => {
    setOpenGenerateModal(true);
    // setQrData(qrValue);
  };

  const dropDownOptions = ["Create single user", "Upload excel sheet to create"];
  const handleDropdownAction = (item, row) => {
    if (item === "Create single user")  handleGenerateModal();  
    if (item === "Upload excel sheet to create") setOpenExcel(true)  

  };

  useEffect(() => {
    const interval = setInterval(() => {
      setProg(50 + Math.floor(Math.random() * 50))
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  
  return (
    <section>
      <div className="search-wrapper">
        <div className="container">
          {/*<div className="dropdown">
            <div
              className="dropdown-btn"
              onClick={(e) => setIsActive(!isActive)}
            >
              {selected}
              <p className="arrow-down">
                <FaSortDown />
              </p>
            </div>

            {isActive && (
              <div className="dropdown-content">
                {options.map((option) => (
                  <div
                    onClick={(e) => {
                      setSelected(option);
                      setIsActive(false);
                    }}
                    className="dropdown-item"
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>*/}

          {/*<SearchAndFilter />*/}
          <div className="mechanics">
            <div className="mechanics-icon" style={{ cursor: 'pointer'}} onClick={()=>setOpenExport(true)}>
              <div className="icon">
                <FaExternalLinkAlt />
              </div>
              <div className="export-data ">Export Bulk Cards</div>
            </div>
            <div onClick={()=>{setOpenGenerateModal(true)}} style={{ cursor: 'pointer'}} className="mechanics-icon">
              <div className="icon">
                <FaPlus />
              </div>
              <div className="generate-code"                
              >
                Generate Bulk Cards              </div>
            </div>
            
            <>
            <br/>
            {showProgress && <Progress percent={prog} showInfo={false} />}
            </>

          </div>
          {selectedCards.length > 0 ? <div className="mechanics-icon" style={{ cursor: 'pointer', background: '#009dff'}}
              onClick={handleReexport}>
              <div className="icon">
                <FaExternalLinkAlt />
              </div>
              <div className="export-data " style={{background: '#009dff'}}>Reexport Selectected Cards</div>
            </div>
            :''}
        </div>
      </div>
      {openGenerateModal && (
        <GenCard
          setCount={setCount}
          count={count}
          handleGenerate={handleGenerate}
          showModal={openGenerateModal}
          hideModal={() => setOpenGenerateModal(false)}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}
    </section>
  );
};

const SearchAndFilter = () => {
  return (
    <div className="search">
      <div className="searchInputs">
        <div className="searchIcon">
          <FaSistrix />
        </div>
        <input
          className="text"
          type="text"
          placeholder="Search users by name, email or date"
        />
      </div>
      <div className="dataResult"></div>
    </div>
  );
};

const Status = ({ selected, setSelected }) => {
  const [isActive, setIsActive] = useState(false);
  const options = ['Active', 'Expired'];
  return (
    <div className="status-container">
      <div className="status">
        <div className="dropdown status-dropdown">
          <div
            className="dropdown-btn status-dropdown-btn"
            onClick={(e) => setIsActive(!isActive)}
          >
            Status
            <p className="arrow-down">
              <FaSortDown />
            </p>
          </div>

          {isActive && (
            <div className="dropdown-content status-dropdown-content">
              {options.map((option) => (
                <div
                  onClick={(e) => {
                    setSelected(option);
                    setIsActive(false);
                  }}
                  className="dropdown-item status-dropdown-item"
                >
                  {option}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="date-container">
        <div>
          <Date />
        </div>
      </div>
    </div>
  );
};

const BulkCard = ({setShowManageQR}) => {
  const [selectedCards, setSelectedCards] = useState([]);
  const [selected, setSelected] = useState('Search by.....');
  const [state, setState] = useState([]);
  const [stat, setStat] = useState([]);
  const [assigned, setAssigned] = useState(0);
  const [unassigned, setUnassigned] = useState(0);
  const [count1, setCount1] = useState(0);
  const [qrData, setQrData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [opengenQr, setOpenGenQr] = useState(false);
  const [showManageMarkert, setShowManageMarkert] = useState(false);
  const [openExcel, setOpenExcel] = useState(false)
  const [openExport, setOpenExport] = useState(false);
  const [totalQRGen, setTotalQRGen] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [selectedRow, setSelectedRow] = useState({})
  const [pagination, setPagination] = useState({ current: 0, pageSize: 20 });
  const [totalQr, setTotalQr] = useState(0)
  const [totalExported, setTotalExported] = useState(0)
  const [count, setCount] = useState("")
  const [adminUsers, setAdminUsers] = useState([]);

  const renderDetailsButton = (params) => {
    const handleModal = (qrValue, row) => {
      setOpenModal(true);
      setQrData(qrValue);
      setSelectedRow(row);
    };
    return (
      <strong>
        <Button
          key={params?.row?.action?.id}
          variant="contained"
          size="small"
          style={{ 
            width: '120px',
            backgroundColor: '#FFF1EC',
            border: '1px solid #FF4B01',
            color: '#FF4B01',
          }}
          // onClick={() => setOpenModal(true)}
          onClick={() => {
            handleModal(params?.row?.qrCode, params?.row?.action)
            // setQrData(params?.row?.action?.qrCode)
          }}
        >
          View QR Code
        </Button>
      </strong>
    );
  };
  // console.log(qrData);
  const dropDownMarketerOpt = ["View users onboard"];
  const handleMarketerAction = (item, row) => {
    if (item === "View users onboard")   setShowManageMarkert(true)
    (true)  

  };

  const columns = [
    {
      field: 'cardOwnerNam',
      headerName: 'select',
      headerClassName: 'super-app-theme--header',
      width: 200,
      renderCell: ({row}) => <input
            type="checkbox"
            // value={setting}
            checked={selectedCards.includes(row?.id)}
            onChange={(e) => {
              const {checked} = e.target;
              setSelectedCards(prevRoomType => checked ? [...prevRoomType, row?.id] : prevRoomType.filter(item => item !== row?.id));
              // // selectedCards.includes(row?.id) ? 
              // selectedCards.push(row?.id)
            }}
        />

   },
    {
      field: 'cardOwnerName',
      headerName: 'NAME',
      headerClassName: 'super-app-theme--header',
      width: 200,
   },
    {
      field: 'cardPan',
      headerName: 'CARD PAN',
      headerClassName: 'super-app-theme--header',
      width: 200,
    },
    {
      field: 'cardType',
      headerName: 'CARD TYPE',
      headerClassName: 'super-app-theme--header',
      width: 200,
    },
    {
      field: 'cardProduct',
      headerName: 'PRODUCT TYPE',
      headerClassName: 'super-app-theme--header',
      width: 200,
      renderCell: ({row}) => row?.cardProduct ? row?.cardProduct : 'N/A'

    },
    {
      field: 'expiryDate',
      headerName: 'EXPIRY DATE',
      headerClassName: 'super-app-theme--header',
      width: 200,
    },
    {
      field: 'assigned',
      headerName: 'ASSIGNMENT STATUS',
      headerClassName: 'super-app-theme--header',
      width: 200,
      renderCell: ({row})=>
      <span
      style={{
        color: `${row.assigned? "#27AE60" : "#EB5757"}`,
      }}
    >
      {row.assigned?'ASSIGNED':'UNASSIGNED'}
      </span>
    },
    {
      field: 'exportStatus',
      headerName: 'EXPORT STATUS',
      headerClassName: 'super-app-theme--header',
      width: 200,
      renderCell: ({row})=>
      <span
      style={{
        color: `${row.exportStatus? "#27AE60" : "#EB5757"}`,
      }}
    >
      {row.exportStatus?'EXPORTED':'NOT EXPORTED'}
      </span>
    },
    {
      field: 'assignedBy',
      headerName: 'ASSIGNED BY',
      headerClassName: 'super-app-theme--header',
      width: 200,
      renderCell: ({row})=>
      <span
      style={{
        color: `${row.assignedBy? "#27AE60" : "#EB5757"}`,
      }}
    >
      {row.assignedBy ? row?.assignedBy :'UNASSIGNED'}
      </span>
    },
    {
      field: 'dateAssigned',
      headerName: 'DATE ASSIGNED',
      headerClassName: 'super-app-theme--header',
      width: 200,
      renderCell: ({row})=>
      <span
      style={{
        color: `${row.dateAssigned? "#27AE60" : "#EB5757"}`,
      }}
    >
      {row.dateAssigned ? row?.dateAssigned :'UNASSIGNED'}
      </span>
    },
    {
      field: 'dateTimeCreated',
      headerName: 'DATE CREATED',
      headerClassName: 'super-app-theme--header',
      width: 250,
      renderCell: ({row}) => row?.dateTimeCreated ? row?.dateTimeCreated : 'N/A'

    },
  ];

  const handleChangeRowsPerPage = async (value) => {
    // console.log(value);
    setPagination({ ...pagination, current: 0, pageSize: value });
    // await getAll(0, value);
  };

  const handleChangePage = async (newPage) => {
    // console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    // await getAll(newPage, pagination.pageSize);
  };


  const getAll = async (page, size) => {
    showLoader();
    const pages = page ? page : 0;
    const limit = size ? size : 20;
     const res = await httpGet(
      `${customBaseUrl.cardUrl}/api/cards`
    );
    if( res.status === true){        
    setState(res?.data);
    // setTotalQr(res?.length)
    }
    hideLoader();
  }

  const getAdmin = async (page, size) => {
    showLoader();
    const pages = page ? page : 0;
    const limit = size ? size : 20;
     const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/admin/users-admin-users?page=0&size=300`
    );
    if(res?._embedded?.userResponse){
      hideLoader();
      const data = res;
      setAdminUsers(data?._embedded?.userResponse);    
     }else if(res?.status=== false){
      hideLoader();
     }else{
      hideLoader();
  
     }  
    hideLoader();
  }

  const getStat = async () => {
    showLoader();

     const res = await httpGet(
      `${customBaseUrl.cardUrl}/api/cards/summary`
    );
    if(res?.status === true){
      hideLoader();
      const data = res;
      setStat(res?.data) 
     }else{
      hideLoader();
  
     }  
    hideLoader();
  }
  useEffect(() => {
    getAdmin();
    getAll();
    getStat();
  }, [refresh]);

  // console.log('state', state);


  const modalCloseAction = () => {
    setOpenModal(false);
    setOpenGenQr(false);
  }

  const handleManageMarketers = () => {
    setShowManageMarkert(true)
  }

  const rows = state?.map((row, index) => ({
    id: row?.id,
    userFullName: row.fullName,
    qrKey: row.qcode,
    age: row.status,
    phoneNo: row.phoneNumber,
    userType: row.userType,
    description: row.description,
    accountNo: row.accountNo,
    dateCreated: row.dateCreated,
    action: row,
  }));

  const handleGenerate = async() => {
    if(!count) return Swal.fire('Oops', 'Count field is empty')
    modalCloseAction();
    let response = await httpGet(`${customBaseUrl.cardUrl}/dummy-account/createBulk/${count}`)
   showLoader();
    if (response.status){    
      hideLoader();
      Swal.fire('Success', response?.message);
      //setQRStatus( response?.message);
     // hideModal(false);
    }else{
     // hideLoader();
      console.log(response?.message)
    }
  }

  return (
    <>
      <div className="App">
        {/* <div>
          <Button
              size="medium"
              style={{ background: "#FF4B01", fontSize: "10px", color: "#fff" }}
              onClick={()=>setShowManageQR(true)}
            >
              Manage Bulk Card
            </Button>
        </div> */}
        <div>
        <Search setSelectedCards={setSelectedCards} selectedCards={selectedCards} refresh={refresh} setRefresh={setRefresh}  setOpenExport={setOpenExport} getAll={getAll }    setShowManageMarkert={setShowManageMarkert} setOpenExcel={setOpenExcel} selected={selected} setSelected={setSelected} />
        <div className="cards-container">
        <div className="cards">
            <p>Total Bulk Cards</p>
            <h2>{stat?.totalGeneratedCards ?? 0}</h2>
          </div>
          <div className="cards">
            <p>Total Exported Bulk Cards</p>
            <h2>{stat?.totalExportedCards ?? 0}</h2>
          </div>
          <div className="cards">
            <p>Total Assigned Bulk Cards</p>
            <h2>{stat?.totalAssignedCards ?? 0}</h2>
          </div>          
          <div className="cards">
            <p>Total Unassigned Bulk Cards</p>
            <h2>{stat?.totalUnassignedCards ?? 0}</h2>
          </div>
          </div>
        </div>
        {//<Status selected={selected} setSelected={setSelected} />
}
        <Box
          sx={{
            width: 1,
            '& .super-app-theme--header': {
              background: '#ff6700',
              color: '#ffffff',
            },
          }}
        >
          <div
            style={{
              height: 650,
              width: '100%',
              background: '#fff',
              borderRadius: '8px',
            }}
          >
            {state.length > 0 && <DataGrid
              // getRowId={row=>row.cardPan}
              rows={state}
              columns={columns}
              rowHeight={125}
              page={pagination.current}
              // paginationMode="server"
              pageSize={pagination.pageSize}
                  onPageSizeChange={(newPageSize) =>
                    handleChangeRowsPerPage(newPageSize)
                  }
                  onPageChange={(newPage) => handleChangePage(newPage)}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  pagination
                  components={{ Toolbar: GridToolbar }}
                  rowCount={state?.length}

            />}
          </div>
        </Box>
      </div>
      {openModal && <GeneratedQr modalState={openModal} modalCloseAction={modalCloseAction } qrValue={qrData} selectedRow={selectedRow}/>}

      {
        openExcel &&
        <ExcelUpload
          showModal={openExcel}
          hideModal={setOpenExcel}
        />
      }
      {
        openExport &&
        <ExportQr
          showModal={openExport}
          hideModal={setOpenExport}
          adminUsers={adminUsers}
        />
      }
    </>
  );
}

export default BulkCard;


